.leaflet-bar .tag-filter-tags-container * {margin: 0; padding: 0;}

.leaflet-bar .tag-filter-tags-container {
    display: none;
    position: absolute;
    top:0px;
    z-index: 1000;
    padding-bottom: 10px;
    padding-left: 5px;
}


.leaflet-bar.easy-button-container.leaflet-control {
    text-align: left !important;
}

.leaflet-bar span.filter-info-box {
    position: absolute;
    margin-top: -5px;
    margin-left: 10px;
    color: white;
    font-size: 12px;
    text-transform: uppercase;
    padding: .2em .6em .3em;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    background-color: #454b52;
    box-sizing: inherit !important;
}

.leaflet-bar .tag-filter-tags-container ul {
    border: 1px solid hsl(180, 40%, 60%);
    box-shadow: 0 1px 7px #999;
    width: 150px; margin: 0 auto;
    overflow-y: auto;
    max-height: 200px;
    -webkit-border-bottom-right-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-bottomright: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.leaflet-bar .tag-filter-tags-container ul li:last-child  {
    border: 0px;
}

.leaflet-bar .tag-filter-tags-container ul.header li:last-child  {
    border: 0px;
}

.leaflet-bar .tag-filter-tags-container ul.header {
    height: 28px;
    overflow: hidden;
    border-top: 1px solid hsl(180, 40%, 60%);
    border-bottom: 1px solid hsl(180, 40%, 60%);
    -webkit-border-bottom-right-radius: 0px;
    -webkit-border-bottom-left-radius: 0px;
    -moz-border-radius-bottomright: 0px;
    -moz-border-radius-bottomleft: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.leaflet-bar .tag-filter-tags-container ul.header li a {
    padding: 0px;
}

.leaflet-bar .tag-filter-tags-container ul.header li {
    text-align: center;
}

.leaflet-bar .tag-filter-tags-container ul li {
    background: #fcfdff;
    list-style-type: none;
    position: relative;
    overflow: hidden;
    cursor: pointer; 
    border-bottom: 1px solid hsl(180, 40%, 60%);
}

.leaflet-bar .tag-filter-tags-container ul li a {
    background-color: transparent !important;
    font-size: 12px; 
    font-family: "Helvetica Neue", Arial, Helvetica, sans-serif;
    color: hsl(180, 40%, 40%);
    display: inline; 
    padding: 5px 15px;
    text-decoration: none;
    cursor: pointer; 
    user-select: none;
    position: relative;
}

.leaflet-bar .tag-filter-tags-container .ink {
    display: block; position: absolute;
    background: hsl(180, 40%, 80%);
    border-radius: 100%;
    transform: scale(0);
}

.leaflet-bar .tag-filter-tags-container .checkbox {
    display: none;
    font-size: 14px;
    color: hsl(180, 40%, 40%);
    margin-left: 5px;
}


.leaflet-bar .tag-filter-tags-container ::-webkit-scrollbar {
    width: 8px;
}
.leaflet-bar .tag-filter-tags-container ::-webkit-scrollbar-button {
    width: 8px;
    height:5px;
}
.leaflet-bar .tag-filter-tags-container ::-webkit-scrollbar-track {
    background:#eee;
    border: thin solid lightgray;
    box-shadow: 0px 0px 3px #dfdfdf inset;
    border-radius:10px;
}
.leaflet-bar .tag-filter-tags-container ::-webkit-scrollbar-thumb {
    background:#999;
    border: thin solid gray;
    border-radius:10px;
}
.leaflet-bar .tag-filter-tags-container ::-webkit-scrollbar-thumb:hover {
    background:#7d7d7d;
}
.leaflet-bar .tag-filter-tags-container a, .leaflet-bar .tag-filter-tags-container .checkbox {
    white-space: nowrap;
}
